export interface EducationListCategory {
  id: string;
  isDefault: boolean;
  title: string;
}

export interface EducationListLessonModel {
  category: EducationListCategory;
  description:
    | {
        English: string;
        Spanish: string;
      }
    | string;
  ext: string;
  id: string;
  iframe:
    | {
        English: string;
        Spanish: string;
      }
    | string;
  isSeen: boolean;
  name:
    | {
        English: string;
        Spanish: string;
      }
    | string;
  preview:
    | {
        English: string;
        Spanish: string;
      }
    | string;
  url:
    | {
        English: string;
        Spanish: string;
      }
    | string;
  position?: string;
  isHidden?: boolean;
}

export interface UserListForFilterModel {
  email: string;
  name: string;
  checked?: boolean;
}

export interface CheckUserCompletePlanModel {
  isComplete: {
    next: string;
    complete: boolean;
  };
}

export enum TrainingLocalesEnum {
  English = 'Eng',
  Spanish = 'Esp',
  FullEnglish = 'English',
  FullSpanish = 'Spanish',
}

export interface TrainingLocalesModel {
  full: TrainingLocalesEnum;
  short: TrainingLocalesEnum;
}

export interface ListOfCategoriesModel {
  id: string;
  isDefault: boolean;
  title: string;
}

export enum SendToEnum {
  AllEmployee = 1,
  EmployeeByName = 2,
  NoOne = 3,
}

export enum LessonMode {
  Iframe = 'iframe',
  File = 'file',
}

export enum LessonLangMode {
  English = 'english',
  Spanish = 'spanish',
}

export interface LessonsLangModel {
  english: {
    mode: LessonMode;
  };
  spanish: {
    mode: LessonMode;
    showSpanish: boolean;
  };
}

export interface FileDataModel {
  english: {
    thumbnail: {
      error: string;
    };
    file: {
      error: string;
    };
  };
  spanish: {
    thumbnail: {
      error: string;
    };
    file: {
      error: string;
    };
  };
}

export interface CreateLessonPayload {
  emailType: SendToEnum;
  category: string;
  emails: string[];
  name: string;
  description: string;
  iframe: string;
}

export interface LessonModel {
  category: {
    id: string;
    title: string;
  };
  description: {
    English: string;
    Spanish: string;
  };
  id: string;
  iframe: {
    English: string;
    Spanish: string;
  };
  name: {
    English: string;
    Spanish: string;
  };
  preview: {
    English: string;
    Spanish: string;
  };
  url: {
    English: string;
    Spanish: string;
  };
}

export interface DeleteFilesModel {
  locale: 'English' | 'Spanish';
  type: 'lesson' | 'preview';
  delete: boolean;
}
